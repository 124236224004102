import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "9e834a50-047e-490a-9395-900e84dc6d93",
        authority: "https://login.microsoftonline.com/583eee9b-f0d1-4652-8766-ee98892568ed",
        redirectUri: "http://localhost:3000",
        postLogoutRedirectUri: "/"
    },
    system: {
        allowNativeBroker: false 
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};